import { Box, Typography, Chip, Tooltip, useTheme, Grid, Card } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import ISF5ApiUrls from 'src/apiUrls/ISF5ApiUrls'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Namespace from 'src/constants/locale/Namespace'
import Table from '../../../components/aciacehighway/Table'
import { FileForUserGetPartyId, FileForUserGetUserDetails, GetFilterByUserIdCriteria } from 'src/common/FileForUserCommon'
import pathMap from 'src/paths/pathMap'
import sweetalert from 'sweetalert'
import { getStatusMetadata } from '../../../common/NACommon'
import moment from 'moment-timezone'
import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import ConfirmDialog from 'src/views/common/ConfirmDialog'
import TemplateSelectDialogWithOption from '../isf5/TemplateSelectDialogWithOption'
import { format } from 'date-fns'
import UserProfileApiUrls from "src/apiUrls/UserProfileApiUrls";

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification
  }
} = components

const {
  filter: { BETWEEN, EQUAL, IN, LIKE }
} = constants

function TablePage(props) {
  const { history, location, manifestId, showNotification } = props
  const [isf5TemplateDialog, setIsf5TemplateDialog] = useState(false)
  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.ACE_ISF
  ])
  const tableRef = useRef(null)
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest } = useServices()
  const [confirmDialog, setConfirmDialog] = useState({ open: false, isf5: null })
  const [sbPartyIdList, setSBPartyIdList] = useState([])
  const [sbPartyIdFetched, setSBPartyIdFetched] = useState(false)

  const { getTranslatedText } = useContext(AceIsfTranslationContext)

  let columns = [
    {
      field: 'cbpTransactionNo',
      sortKey: 'cbpTransactionNo',
      title: getTranslatedText('ACE_ISF', 'cbpTransactionNo'),
    },
    {
      field: 'importerRefNo',
      sortKey: 'importerRefNo',
      title: getTranslatedText('ACE_ISF', 'importerRefNo'),
    },
    {
      title: getTranslatedText('ACE_ISF', 'shipmentReference'),
      render: (data) => {
        let content = []
        data.shipments.map(function (object, i) {
          content.push(
            <Chip key='first' label={object.blType + " | " + object.blNo + (object.blMatched ? " | " + object.blMatched : "")} />
          )
        })

        return (
          <>
            <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
              {content}
            </Box>
          </>
        )
      }
    },
    {
      field: 'refBookingNo',
      sortKey: 'refBookingNo',
      title: getTranslatedText('ACE_ISF', 'refBookingNo'),
    },
    {
      field: 'status',
      sortKey: 'status',
      title: getTranslatedText('ACE_ISF', 'status'),
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <>
            <Tooltip key='more' title='View response(s)' placement='top'>
              <Chip
                label={status.label}
                size='small'
                style={{
                  backgroundColor: status.color,
                  color: status.contrastColor
                }}
                clickable
              // onClick={(event) => {
              //   event.stopPropagation()
              //   setQuickGlanceResponseLogDialog({ open: true, manifest: data })                
              // }}
              />
            </Tooltip>
            <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
              {data.responseDateTime && moment(data.responseDateTime).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')}
            </Typography>
          </>
        )
      }
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: getTranslatedText('ACE_ISF', 'createdDateTime'),
      render: (data) =>
        data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'submittedDateTime',
      sortKey: 'submittedDateTime',
      title: getTranslatedText('ACE_ISF', 'submittedDateTime'),
      render: (data) => data.submittedDateTime && moment(data.submittedDateTime).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    }
    //responseDateTime
  ]

  const filters = [
    {
      label: getTranslatedText('ACE_ISF', 'cbpTransactionNo'),
      type: 'textfield',
      name: 'cbpTransactionNo',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('ACE_ISF', 'importerRefNo'),
      type: 'textfield',
      name: 'importerRefNo',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('ACE_ISF', 'refBookingNo'),
      type: 'textfield',
      name: 'refBookingNo',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('ACE_ISF', 'status'),
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: '1005'
          }
        },
        {
          label: 'Accepted',
          value: 'accepted',
          filterValue: {
            value: '1000'
          }
        },
        {
          label: 'Sent',
          value: 'sent',
          filterValue: {
            value: '1008'
          }
        },
        {
          label: 'Rejected',
          value: 'rejected',
          filterValue: {
            value: '1001'
          }
        },
        /*{
          label: 'Template',
          value: 'Template',
          filterValue: {
            value: '1002'
          }
        },*/
        {
          label: 'Status Notification',
          value: 'status_notification',
          filterValue: {
            value: '1006'
          }
        },
        {
          label: 'Cancellation Accepted',
          value: 'cancellation_accepted',
          filterValue: {
            value: '1003'
          }
        },
        {
          label: 'Cancellation Rejected',
          value: 'cancellation_rejected',
          filterValue: {
            value: '1004'
          }
        },
        {
          label: 'Modification Rejected',
          value: 'modification_rejected',
          filterValue: {
            value: '1007'
          }
        }
      ]
    },
    {
      label: getTranslatedText('ACE_ISF', 'createdDateTime'),
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: getTranslatedText('ACE_ISF', 'submittedDateTime'),
      type: 'daterange',
      name: 'submittedDateTime',
      operatorType: BETWEEN
    },
  ]

  function handleCloneISF5(data) {
    createRecord.execute(
      ISF5ApiUrls.CLONE,
      data,
      (data) => {
        data.cloneFrom = data.importerRefNo;
        // const shipmentDataList = data.shipments.map(
        //   (shipment, index) => ({ ...shipment, _id: index + 1 })
        // )
        // data.shipments = shipmentDataList

        // const partyDataList = data.parties.map(
        //   (party, index) => ({ ...party, _id: index + 1 })
        // )
        // data.parties = partyDataList

        // const containerDataList = data.containers.map(
        //   (container, index) => ({ ...container, _id: index + 1 })
        // )
        // data.containers = containerDataList
        // data.expArrivalTime = moment(data.expArrivalDate).format('HH:mm');
        // data.expArrivalDate = moment(data.expArrivalDate).format('YYYY-MM-DD');
        history.push(pathMap.ACEISF5_ADD_VIEW, data)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handlePrintIsf5(data) {
    const config = { responseType: 'blob' }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST',
      ISF5ApiUrls.PRINTISF5,
      data,
      (response) => {
        let blob = new Blob([response.data])

        let first = 'ISF_5_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + '.pdf'
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }

  function handleDeleteISF5() {
    if (confirmDialog.isf5) {
      deleteRecord.execute(
        ISF5ApiUrls.DELETE,
        confirmDialog.isf5,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, isf5: null })
          setIsf5TemplateDialog(false)
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  useEffect(() => {
    let loginId = "";
    let userPreference = localStorage.getItem("userPreference");
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setSBPartyIdList([])
      setSBPartyIdFetched(true);
    } else {
      let userPreferenceJSON = JSON.parse(userPreference);
      loginId = userPreferenceJSON.tprUserDetail.userProfile.loginId;
    }
    console.log("loginId: " + JSON.stringify(loginId));

    if (loginId !== "") {
      function onSuccess(response) {
        setSBPartyIdList(response.data)
        setSBPartyIdFetched(true);
        console.log("sbPartyIdList: " + JSON.stringify(sbPartyIdList));
      }

      function onError(error) {
        console.log("sbPartyIdList Error: " + JSON.stringify(error));
      }

      function onComplete() { }
      const config = {}

      securedSendRequest.execute('POST', UserProfileApiUrls.SB_PARTYID_LIST, { loginId }, onSuccess, onError, onComplete, config, null);

    }
  }, [])

  let filter = GetFilterByUserIdCriteria("ACEISF")

  return (
    <>
      {sbPartyIdFetched && (<Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.ACEISF5_ADD_VIEW)
            },
            label: 'Create ISF'
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => setIsf5TemplateDialog(true)
            },
            label: 'ISF Template'
          }
        ]}
        columns={columns}
        compact
        // exportData={{ url: ISF10ApiUrls.CUSTOM_EXPORT }}
        fetch={{ url: ISF5ApiUrls.GET }}
        fetchFilters={[
          { field: 'templateFlag', operator: EQUAL, value: '0' },
          { field: 'partyId', operator: IN, value: sbPartyIdList.length > 0 ? sbPartyIdList : FileForUserGetPartyId() },
          ...filter
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (rowData.status === '1008') {
            history.push(`${location.pathname}/view/${rowData.id}`)
          }
          else {
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
        }
        }
        rowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: getTranslatedText('ACE_ISF', 'editButton'),
            onClick: (rowData) =>
              history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              rowData.status === '1008'
                ? getTranslatedText('ACE_ISF', 'editSentRecords')
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: getTranslatedText('ACE_ISF', 'cloneButton'),
            onClick: handleCloneISF5
          },
          {
            label: getTranslatedText('ACE_ISF', 'printISF5'),
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            onClick: handlePrintIsf5
          },
          {
            disabled: (data) => data.status !== '1005',
            label: getTranslatedText('ACE_ISF', 'deleteButton'),
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (data) => setConfirmDialog({ open: true, isf5: data }),
            tooltip: (rowData) =>
              rowData.status !== '1005'
                ? getTranslatedText('ACE_ISF', 'deleteRecords')
                : null
          },
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        tableRef={tableRef}
        checkboxSelection
        tablePreference={{ module: 'ISF5', key: 'isf5_table_visible_column' }}
      />)}
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, isf5: null })}
        confirmDialog={handleDeleteISF5}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={getTranslatedText('ACE_ISF', 'deleteButton')}
      />
      <TemplateSelectDialogWithOption
        isfTemplateDialog={isf5TemplateDialog}
        setIsfTemplateDialog={setIsf5TemplateDialog}
        setConfirmDialog={setConfirmDialog}
        showNotification={showNotification}
        isTable={true}
        location={location}
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: 'Edit',
            onClick: (rowData) =>
              history.push(pathMap.ACEISF5+`/edit/${rowData.id}`),
            tooltip: () => 'edit'
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: 'Delete',
            onClick: (rowData) =>
              setConfirmDialog({ open: true, isf5: rowData }),
            tooltip: () => 'delete'
          }
        ]}
      />
    </>
  )
}

export default TablePage
